import * as React from "react";

interface Props {
    label?: React.ReactNode;
}

interface UncontrolledProps {
    initial?: boolean;
}
export const Checkbox: React.FC<
    Props & React.InputHTMLAttributes<HTMLInputElement>
> = props => {
    const { label, className, ...rest } = props;
    return (
        <div className={`${className ?? ""} w-full`}>
            <label className="inline-flex items-center">
                <input
                    type="checkbox"
                    className="form-checkbox rounded disabled:bg-gray-100 border-gray-400 hover:border-brand-600 text-brand-600 active:border-brand-400 focus:ring-2 focus:ring-brand-300"
                    {...rest}
                />
                {label ? <div className="ml-2 whitespace-nowrap">{label}</div> : null}
            </label>
        </div>
    );
};

export const CheckboxUncontrolled: React.FC<
    Props & UncontrolledProps & React.InputHTMLAttributes<HTMLInputElement>
> = props => {
    const { label, className, initial, onChange, ...rest } = props;
    const [isChecked, setIsChecked] = React.useState(initial);
    const realOnChange = React.useCallback(
        (e: React.ChangeEvent<HTMLInputElement>) => {
            setIsChecked(e.target.checked);
            if (onChange) {
                onChange(e);
            }
        },
        [onChange, setIsChecked],
    );
    return (
        <Checkbox
            label={label}
            checked={isChecked}
            onChange={realOnChange}
            className={className}
            {...rest}
        />
    );
};
